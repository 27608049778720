import React, { ReactNode } from 'react';
import { Container, Flex, Spacer } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import SearchModal from '../components/SearchModal';
import DepartmentModal from '../components/DepartmentModal';
import AnnounceModal from '../components/AnnounceModal';
import NavBar from './NavBar';
import Footer from './Footer';
import ImportantNoticeModal from './ImportantNoticeModal';
// import OndemandProfileUpdateModal from './OndemandProfileUpdateModal';
import ProfileUpdateContainer from './ProfileUpdateContainer';

type Props = {
  children: ReactNode;
};

function Layout({ children }: Props) {
  const pathname = useRouter().pathname;
  // 特集ページ関係のみレイアウト調整
  const isSpecialPage = pathname.startsWith('/special');
  const isLoginPage = pathname === '/login';
  const isProfilePage = pathname.startsWith('/profile');
  let paddingProps;
  if (isSpecialPage) {
    paddingProps = { pt: 0, pl: 0, pr: 0 };
  } else if (isLoginPage) {
    paddingProps = {
      pt: [20, null, null, '6rem'],
      pl: ['initial', 'initial', 'initial'],
      pr: ['initial', 'initial', 'initial'],
    };
  } else {
    paddingProps = {
      pt: [24, null, isProfilePage ? 24 : 20, '7rem'],
      pl: ['initial', 'initial', 'initial'],
      pr: ['initial', 'initial', 'initial'],
    };
  }

  return (
    <Flex minH="100vh" direction="column" overflowX="hidden">
      <SearchModal />
      <AnnounceModal />
      <ImportantNoticeModal />
      <DepartmentModal />
      {/* 2023.08.15 オンデマンドプロフィール更新誘導無効化
      <OndemandProfileUpdateModal />
      */}
      <ProfileUpdateContainer />
      <NavBar />
      <Container {...paddingProps} pb={16}>
        {children}
      </Container>
      <Spacer />
      <Footer />
    </Flex>
  );
}

export default Layout;
