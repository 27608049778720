import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Field, Form } from 'react-final-form';
import { ValidationErrors } from 'final-form';
import * as yup from 'yup';
import useApiClient from '../hooks/useApiClient';
import { sendAnalyticsEvent } from '../common/libs/googleAnalytics';
import { DEPARTMENT_ID_OTHER_STRING } from '../common/constants';
import monitoring from '../common/libs/monitoring';

type FormValues = {
  department?: string;
  otherDepartment?: string | null;
};

type Department = {
  id: number;
  name: string;
};

type SubmitResult = 'submitSuccess' | 'submitFailure' | 'submitNetworkError';

function ProfileDepartmentUpdateModalContent({
  onClose,
}: {
  onClose: (result: any) => void;
}) {
  const [submitResult, setSubmitResult] = useState<SubmitResult>();
  const [departments, setDepartments] = useState<Department[]>([]);
  const { apiClient } = useApiClient();

  const fetchDepartments = useCallback(async () => {
    try {
      const res = await apiClient('/api/departments', {
        method: 'GET',
      });
      if (res.ok) {
        const data = await res.json();
        setDepartments(data.departments);
      } else {
        throw new Error();
      }
    } catch (e) {
      monitoring.notify({
        name: `API ERROR DETECTED ${e}`,
        message: '診療科の取得に失敗しました。',
      });
    }
  }, [apiClient]);

  useEffect(() => {
    (async () => {
      sendAnalyticsEvent('profile_department_update_induction', 'show', '');
      await fetchDepartments();
    })();
  }, [fetchDepartments]);

  const validate = (values: FormValues): ValidationErrors | undefined => {
    const errors: ValidationErrors = {
      department: null,
      otherDepartment: null,
    };

    const schemas = {
      department: yup.number().required(),
      otherDepartment: yup.string().nullable().max(255),
    };

    if (!schemas.department.isValidSync(values.department)) {
      errors.department = '診療科を選択してください。';
    }

    if (String(values.department) === DEPARTMENT_ID_OTHER_STRING) {
      if (!values.otherDepartment) {
        errors.otherDepartment =
          '「その他診療科」のご入力のお願い：プロフィール「その他診療科」欄のご入力がお済みでないようです。';
      } else if (!schemas.otherDepartment.isValidSync(values.otherDepartment)) {
        errors.otherDepartment =
          'その他診療科は255文字以内で入力してください。';
      }
    }

    const hasNoError = Object.values(errors).every((v) => v === null);
    if (hasNoError) {
      return undefined;
    } else {
      return errors;
    }
  };

  const initialFormValues: FormValues = {
    department: '1',
    otherDepartment: null,
  };

  const onSubmit = async (values: FormValues) => {
    // プロフィール情報診療科更新APIコール
    try {
      sendAnalyticsEvent(
        'profile_department_update_induction',
        'click',
        'update',
      );

      const res = await apiClient('/api/profile/department', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      if (res.ok) {
        setSubmitResult('submitSuccess');
        onClose(true);
      } else {
        setSubmitResult('submitFailure');
      }
    } catch (e) {
      setSubmitResult('submitNetworkError');
    }

    // エラー情報はstateで管理
    return undefined;
  };

  return (
    <ModalContent>
      <ModalHeader>
        <Center mt={4}>
          <Text fontSize={'xl'} fontWeight={'bold'}>
            診療科登録のお願い
          </Text>
        </Center>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text mb="2rem">
          当アプリは高度な医療情報を提供することを目的としたアプリであり、薬機法及びアプリストア規約の観点から、診療科の登録をお願いしております。
        </Text>
        <Form
          initialValues={initialFormValues}
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, values, submitting }) => (
            <>
              <VStack as="form" spacing={4}>
                <Field name="department">
                  {({ input, meta }) => (
                    <FormControl
                      id="department"
                      isInvalid={meta.touched && meta.error}
                    >
                      <HStack>
                        <FormLabel>診療科</FormLabel>
                        <FormHelperText pb={2}>
                          ご自分に適した情報が届きます
                        </FormHelperText>
                      </HStack>
                      <Select
                        value={input.value}
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                      >
                        {departments.map((p) => (
                          <option key={p.id} value={p.id}>
                            {p.name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                      <FormHelperText ml={2}>
                        該当するものが無い場合は「その他」をお選び下さい。
                      </FormHelperText>
                    </FormControl>
                  )}
                </Field>

                <Field name="otherDepartment">
                  {({ input, meta }) => (
                    <FormControl
                      pl={10}
                      id="otherDepartment"
                      isInvalid={meta.touched && meta.error}
                      hidden={
                        String(values.department) !== DEPARTMENT_ID_OTHER_STRING
                      }
                    >
                      <FormLabel>その他診療科</FormLabel>
                      <Input
                        type="text"
                        {...input}
                        placeholder="診療科のリストに該当するものが無い場合はこちらに入力してください。"
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                      <FormHelperText ml={2}>
                        研究医の方等、特定の診療科に従事されていない場合等は「診療科なし」とご記入下さい。
                      </FormHelperText>
                    </FormControl>
                  )}
                </Field>
              </VStack>

              <Center>
                <Button
                  isLoading={submitting}
                  colorScheme="brand"
                  width={'50%'}
                  mt={6}
                  mb={4}
                  type="submit"
                  isDisabled={
                    values.department === DEPARTMENT_ID_OTHER_STRING &&
                    !values.otherDepartment
                  }
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  登録する
                </Button>
              </Center>
            </>
          )}
        />
      </ModalBody>
    </ModalContent>
  );
}

export type DepartmentUpdateModalProps = {
  isOpen: boolean;
  onClose: (result: any) => void; // [memo] resultには更新して閉じた場合はtrueを、×ボタンで閉じた場合はfalseを渡す想定
};

export default function ProfileDepartmentUpdateModal(
  props: DepartmentUpdateModalProps,
) {
  const onClose = () => {
    sendAnalyticsEvent('profile_department_update_induction', 'click', 'close');
    props.onClose(false);
  };

  return (
    <Modal
      size={'lg'}
      blockScrollOnMount={false}
      isOpen={props.isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ProfileDepartmentUpdateModalContent onClose={props.onClose} />
    </Modal>
  );
}
