import {
  Box,
  Container,
  Divider,
  HStack,
  Link,
  SimpleGrid,
  Spacer,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import { ChevronRightIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import NextImage from 'next/image';
import NextLink from 'next/link';
import { ReactNode } from 'react';
import {
  URL_CORPORATE_PRIVACY_POLICY,
  URL_CORPORATE_TERMS,
  URL_CORPORATE_TOP,
  URL_ANTAA_FACEBOOK,
  URL_STORE_APPLE,
  URL_STORE_GOOGLE,
  URL_ANTAA_TWITTER,
} from '../common/constants';
import { sendAnalyticsEvent } from '../common/libs/googleAnalytics';

function FooterLink({
  href,
  children,
  isExternal = true,
}: {
  href: string;
  children: string;
  isExternal?: boolean;
}) {
  return (
    <Link
      display="inline-block"
      pr={4}
      py={1}
      href={href}
      color="footerText"
      fontSize="xs"
      isExternal={isExternal}
    >
      {children}
      <ChevronRightIcon color="brand.company" />
    </Link>
  );
}

function FooterLinkBox({
  href,
  children,
  isExternal = true,
}: {
  href: string;
  children: string;
  isExternal?: boolean;
}) {
  return (
    <Box>
      <FooterLink href={href} isExternal={isExternal}>
        {children}
      </FooterLink>
    </Box>
  );
}

function FooterSocialIconBox({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}) {
  return (
    <Box>
      <Link
        display="inline-block"
        px={2}
        py={1}
        mx={1}
        mt={2}
        href={href}
        color="footerText"
        fontSize="xs"
        isExternal
      >
        {children}
      </Link>
    </Box>
  );
}

function Footer() {
  const { colorMode } = useColorMode();
  return (
    <Box as="footer" bg="footer" p={[2, 2, 4, 4]} w="full">
      <Container>
        <SimpleGrid columns={[1, 1, 3]}>
          <Box>
            <NextLink href="https://slide.antaa.jp" passHref>
              <Link
                display="flex"
                translateY="2px"
                transform="auto"
                flexShrink={0}
                onClick={() =>
                  sendAnalyticsEvent('link_to', 'slide', 'from_footer')
                }
              >
                <NextImage
                  src={
                    colorMode === 'light'
                      ? '/images/logo_slide_without_antaa_logo.png'
                      : '/images/logo_slide_white_large.png'
                  }
                  alt="Antaa Slide"
                  width={140}
                  height={30}
                  objectFit="contain"
                />
              </Link>
            </NextLink>
            <Text fontSize="xx-small" color="footerText">
              医師・医学生のためのスライド共有
            </Text>
            <Link
              color="footerText"
              href={'https://note.com/antaa/m/m3b899fb4c8fe'}
              isExternal
              fontSize="xs"
              onClick={() =>
                sendAnalyticsEvent('link_to', 'note', 'interview_from_footer')
              }
            >
              投稿者インタビュー <ExternalLinkIcon ml={1} mb={1} />
            </Link>
          </Box>
          <Box mt={[4, 4, 0]}>
            <NextLink href="https://qa.antaa.jp" passHref>
              <Link
                display="flex"
                translateY="2px"
                transform="auto"
                flexShrink={0}
                onClick={() =>
                  sendAnalyticsEvent('link_to', 'qa', 'from_footer')
                }
              >
                <NextImage
                  src={
                    colorMode === 'light'
                      ? '/images/logo_qa.png'
                      : '/images/logo_qa_white.png'
                  }
                  alt="Antaa QA"
                  width={140}
                  height={30}
                  objectFit="cover"
                />
              </Link>
            </NextLink>
            <Text fontSize="xx-small" color="footerText">
              医師同士の質問解決プラットフォーム
            </Text>
            <HStack>
              <Link href={URL_STORE_APPLE} target="_blank">
                <NextImage
                  src="/images/store_ios.svg"
                  alt="App Storeからダウンロード"
                  width={110}
                  height={40}
                  objectFit="contain"
                />
              </Link>
              <Link href={URL_STORE_GOOGLE} target="_blank">
                <NextImage
                  src="/images/store_android.png"
                  alt="Google Play Storeからダウンロード"
                  width={150}
                  height={60}
                  objectFit="contain"
                />
              </Link>
            </HStack>
          </Box>
        </SimpleGrid>

        <Divider mb={4} />
        <Box display={['block', 'block', 'flex', 'flex']} alignItems="flex-end">
          <FooterLinkBox href={URL_CORPORATE_TOP}>会社概要</FooterLinkBox>
          <FooterLinkBox href={URL_CORPORATE_TERMS}>利用規約</FooterLinkBox>
          <FooterLinkBox href={URL_CORPORATE_PRIVACY_POLICY}>
            プライバシーポリシー
          </FooterLinkBox>
          <FooterLinkBox href="/docs/community-guidelines" isExternal={false}>
            Antaaガイドライン
          </FooterLinkBox>
          <FooterLinkBox href="/docs/ip-guideline" isExternal={false}>
            知的財産取扱いガイドライン
          </FooterLinkBox>
          <Spacer />
          <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Text
              fontSize="xx-small"
              color="footerText"
              py={2}
              px={[0, 0, 2, 2]}
              pt={[3, 3, null, null]}
            >
              Antaa, Inc. All rights reserved.
            </Text>
            <Box display="flex">
              <FooterSocialIconBox href={URL_ANTAA_FACEBOOK}>
                <NextImage
                  src="/images/facebook_icon.png"
                  alt="Follow us on Facebook"
                  width={30}
                  height={30}
                  objectFit="contain"
                />
              </FooterSocialIconBox>
              <FooterSocialIconBox href={URL_ANTAA_TWITTER}>
                <NextImage
                  src="/images/twitter_icon.png"
                  alt="Follow us on Twitter"
                  width={30}
                  height={30}
                  objectFit="contain"
                />
              </FooterSocialIconBox>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
