import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  Link,
  List,
  ListItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { Field, Form } from 'react-final-form';
import { ValidationErrors } from 'final-form';
import * as yup from 'yup';
import useApiClient from '../hooks/useApiClient';
import { sendAnalyticsEvent } from '../common/libs/googleAnalytics';
import {
  URL_CORPORATE_PRIVACY_POLICY,
  URL_INQUIRY_FORM,
} from '../common/constants';
import { HOSPITAL_MAX_FETCH_NUM } from '../common/constants';
import monitoring from '../common/libs/monitoring';

type FormValues = {
  hospitalId?: string | null;
  hospitalName?: string | null;
};

type Hospital = {
  id: string;
  name: string;
};

type SubmitResult = 'submitSuccess' | 'submitFailure' | 'submitNetworkError';

/**
 * 勤務病院/学校/勤務先サジェストHooks
 */
function useHospitalList() {
  const DEFAULT_FETCH_NUM = 10;
  const { apiClient } = useApiClient();
  const [hospitalList, setHospitalList] = useState<Hospital[]>([]);
  const [totalHospitalCount, setTotalHospitalCount] = useState(0);
  const [shouldShowHospitalList, setShouldShowHospitalList] = useState(false);

  const fetchHospitalList = useCallback(
    async (keyword: string, limit: number = DEFAULT_FETCH_NUM) => {
      try {
        const res = await apiClient(
          `/api/hospital/prefixed-with?q=${encodeURIComponent(
            keyword,
          )}&limit=${limit}`,
          {
            method: 'GET',
          },
        );
        if (res.ok) {
          const data = await res.json();
          setTotalHospitalCount(data.totalHospitalCount);
          setHospitalList(data.hospitals);
        } else {
          throw new Error();
        }
      } catch (e) {
        monitoring.notify({
          name: `API ERROR DETECTED ${e}`,
          message: '検索予測キーワードの取得に失敗しました。',
        });
      }
    },
    [apiClient],
  );

  return {
    hospitalList,
    totalHospitalCount,
    shouldShowHospitalList,
    setShouldShowHospitalList,
    fetchHospitalList,
  };
}

function ProfileHospitalUpdateModalContent({
  onClose,
}: {
  onClose: (result: any) => void;
}) {
  const {
    hospitalList,
    totalHospitalCount,
    shouldShowHospitalList,
    setShouldShowHospitalList,
    fetchHospitalList,
  } = useHospitalList();
  const [isFetchingMoreHospitals, setFetchingMoreHospitals] =
    useState<boolean>(false);
  const [submitResult, setSubmitResult] = useState<SubmitResult>();
  const { apiClient } = useApiClient();

  const onClickHospitalInput = (e: any, values: FormValues) => {
    const trimmedInputKeyword = e.currentTarget.value.trim();
    if (hospitalList.length === 0 && trimmedInputKeyword.length > 0) {
      fetchHospitalList(trimmedInputKeyword);
    }
    if (!shouldShowHospitalList && hospitalList.length > 0) {
      setShouldShowHospitalList(true);
    }
  };

  const onChangeHospitalInput = (e: any, values: FormValues) => {
    const trimmedInputKeyword = e.currentTarget.value.trim();
    values.hospitalId = null;
    values.hospitalId = null;
    if (trimmedInputKeyword.length > 0) {
      fetchHospitalList(trimmedInputKeyword);
      setShouldShowHospitalList(true);
    }
  };

  const closeHospitalList = () => {
    setShouldShowHospitalList(false);
  };

  const onBlurHospitalInput = () => {
    setTimeout(
      (isFetchingMoreHospitals) => {
        if (!isFetchingMoreHospitals) {
          closeHospitalList();
        }
      },
      300,
      isFetchingMoreHospitals,
    );
  };

  const onSelectHospital = (
    hospitalId: string,
    hospitalName: string,
    values: FormValues,
  ) => {
    values.hospitalId = hospitalId;
    values.hospitalName = hospitalName;
    closeHospitalList();
  };

  const fetchMoreHospitalList = async (e: any, values: FormValues) => {
    setFetchingMoreHospitals(true);
    const trimmedInputKeyword = values.hospitalName || '';
    await fetchHospitalList(trimmedInputKeyword, HOSPITAL_MAX_FETCH_NUM);
    setShouldShowHospitalList(true);
    setFetchingMoreHospitals(false);
  };

  const validate = (values: FormValues): ValidationErrors | undefined => {
    const errors: ValidationErrors = {
      hospitalId: null,
      hospitalName: null,
    };

    const schemas = {
      hospitalName: yup.string().required().min(1).max(100),
    };

    if (!schemas.hospitalName.isValidSync(values.hospitalName)) {
      errors.hospitalName =
        '勤務先を1文字以上、100文字以内で入力してください。';
    }

    const hasNoError = Object.values(errors).every((v) => v === null);
    if (hasNoError) {
      return undefined;
    } else {
      return errors;
    }
  };

  const initialFormValues: FormValues = {
    hospitalId: null,
    hospitalName: null,
  };

  const onSubmit = async (values: FormValues) => {
    // プロフィール情報所属医療機関更新APIコール
    try {
      sendAnalyticsEvent(
        'profile_hospital_update_induction',
        'click',
        'update',
      );

      const res = await apiClient('/api/profile/hospital', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      if (res.ok) {
        setSubmitResult('submitSuccess');
        onClose(true);
      } else {
        setSubmitResult('submitFailure');
      }
    } catch (e) {
      setSubmitResult('submitNetworkError');
    }

    // エラー情報はstateで管理
    return undefined;
  };

  return (
    <ModalContent>
      <ModalHeader>
        <Center mt={4}>
          <Text fontSize={'xl'} fontWeight={'bold'}>
            勤務先登録のお願い
          </Text>
        </Center>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text mb="1rem">
          当アプリは高度な医療情報を提供することを目的としたアプリであり、薬機法及びアプリストア規約の観点から、勤務先の登録をお願いしております。
        </Text>
        <Form
          initialValues={initialFormValues}
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, values, submitting }) => (
            <>
              <Field name="hospitalName">
                {({ input, meta }) => (
                  <FormControl
                    id="hospital"
                    isInvalid={meta.touched && meta.error}
                    isRequired
                  >
                    <Input
                      type="text"
                      {...input}
                      placeholder="例）アンター病院"
                      onInput={(e) => onChangeHospitalInput(e, values)}
                      onClick={(e) => onClickHospitalInput(e, values)}
                      onBlur={onBlurHospitalInput}
                      mt={4}
                    />
                    {shouldShowHospitalList && (
                      <List
                        bgColor="gray.100"
                        color="black"
                        borderRadius="md"
                        fontSize="sm"
                        spacing={2}
                        pt={2}
                        pb={2}
                        pl={1}
                        pr={1}
                        w="100%"
                        maxH="200px"
                        overflowY="scroll"
                      >
                        {hospitalList.map((hospital, i) => (
                          <>
                            {i > 0 && <Divider borderColor="gray.300" />}
                            <ListItem
                              key={i}
                              pl={3}
                              pr={3}
                              onClick={() =>
                                onSelectHospital(
                                  hospital.id,
                                  hospital.name,
                                  values,
                                )
                              }
                            >
                              {`${hospital.name}`}
                            </ListItem>
                          </>
                        ))}
                        {hospitalList.length < totalHospitalCount &&
                          hospitalList.length < HOSPITAL_MAX_FETCH_NUM && (
                            <>
                              <Divider borderColor="gray.300" />
                              <ListItem
                                key={'fetch_more'}
                                pl={3}
                                pr={3}
                                onClick={(e) =>
                                  fetchMoreHospitalList(e, values)
                                }
                                textAlign={'center'}
                              >
                                もっとみる
                              </ListItem>
                            </>
                          )}
                      </List>
                    )}
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Center mb={4}>
                <Link
                  colorScheme="brand"
                  href={URL_INQUIRY_FORM}
                  rel="noopener"
                  target="_blank"
                  fontSize="xs"
                  mt={4}
                  isExternal
                >
                  ※勤務先が見つからない場合はこちら
                </Link>
              </Center>

              <Center pt={10} mt={3} mb={4}>
                <Button
                  isLoading={submitting}
                  colorScheme="brand"
                  width={'50%'}
                  type="submit"
                  isDisabled={!values.hospitalId}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  登録する
                </Button>
              </Center>
              <Center mb={4}>
                <Link
                  colorScheme="brand"
                  href={URL_CORPORATE_PRIVACY_POLICY}
                  rel="noopener"
                  target="_blank"
                  fontSize="xs"
                  isExternal
                >
                  プライバシーポリシー
                </Link>
              </Center>
            </>
          )}
        />
      </ModalBody>
    </ModalContent>
  );
}

export type HospitalUpdateModalProps = {
  isOpen: boolean;
  onClose: (result: any) => void; // [memo] resultには更新して閉じた場合はtrueを、×ボタンで閉じた場合はfalseを渡す想定
};

export default function ProfileHospitalUpdateModal(
  props: HospitalUpdateModalProps,
) {
  useEffect(() => {
    sendAnalyticsEvent('profile_hospital_update_induction', 'show', '');
  });

  const onClose = () => {
    sendAnalyticsEvent('profile_hospital_update_induction', 'click', 'close');
    props.onClose(false);
  };

  return (
    <Modal
      size={'lg'}
      blockScrollOnMount={false}
      isOpen={props.isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ProfileHospitalUpdateModalContent onClose={props.onClose} />
    </Modal>
  );
}
