/* eslint-disable @next/next/next-script-for-ga */
import React, { useEffect } from 'react';
import type { AppInitialProps, AppProps } from 'next/app';
import NextHead from 'next/head';
import { ChakraProvider } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import theme from '../theme';
import Layout from '../components/Layout';
import { AppContextProvider, useAppContext } from '../contexts/app';
import '../styles/globals.css';
import { getPublicRuntimeConfig } from '../common/config';
import { initFirebase } from '../common/libs/firebase';
import type { NextPageWithLayout } from '../common/types';
import {
  useLogRocket,
  setLogRocketUser,
} from '../common/libs/logRocketForArticleUpload';
import { TWITTER_ACCOUNT_CORPORATE } from '../common/constants';
import * as analytics from '../common/libs/googleAnalytics';
import monitoring from '../common/libs/monitoring';
import { patchBigInt } from '../server/patch/bigInt';
import { title } from '../common/libs/metadata';

// todo: next/scriptを使用してscriptタグの書き換え
// import Script from 'next/script';

// JSON.stringify() で `TypeError: Do not know how to serialize a BigInt`
// が出る不具合のパッチ
patchBigInt();

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function useAuth({ pageProps }: AppInitialProps) {
  const { setUser } = useAppContext();

  useEffect(() => {
    if (pageProps.auth) {
      setUser(pageProps.auth.user);
      if (pageProps.auth.user) {
        analytics.setUserId(pageProps.auth.user.id);
        monitoring.setUser(pageProps.auth.user.id);
        setLogRocketUser(
          pageProps.auth.user.id,
          pageProps.auth.user.discloseId || null,
        );
      }
    }
  }, [pageProps, setUser]);
  return null;
}

function useCsrf({ pageProps }: AppInitialProps) {
  const { setCsrfToken } = useAppContext();
  useEffect(() => {
    if (pageProps.csrfToken) {
      setCsrfToken(pageProps.csrfToken);
    }
  }, [pageProps, setCsrfToken]);
  return null;
}

// 利用規約の同意の確認をする
function useTermsOfServiceAgreement({ pageProps }: AppInitialProps) {
  const { termsOfServiceAgreement, setTermsOfServiceAgreement } =
    useAppContext();
  useEffect(() => {
    if (
      pageProps.auth &&
      pageProps.auth.user &&
      pageProps.auth.user.isTermsOfServiceAgree !== termsOfServiceAgreement
    ) {
      setTermsOfServiceAgreement(pageProps.auth.user.isTermsOfServiceAgree);
    }
  }, [pageProps, termsOfServiceAgreement, setTermsOfServiceAgreement]);
  return null;
}

// プライバシーポリシーの同意の確認をする
function usePrivacyPolicyAgreement({ pageProps }: AppInitialProps) {
  const { privacyPolicyAgreement, setPrivacyPolicyAgreement } = useAppContext();
  useEffect(() => {
    if (
      pageProps.auth &&
      pageProps.auth.user &&
      pageProps.auth.user.isPrivacyPolicyAgree !== privacyPolicyAgreement
    ) {
      setPrivacyPolicyAgreement(pageProps.auth.user.isPrivacyPolicyAgree);
    }
  }, [pageProps, privacyPolicyAgreement, setPrivacyPolicyAgreement]);
  return null;
}

function usePageView() {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (path: string) => {
      analytics.pageView(path);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
}

function GaScript() {
  const {
    gaEnableTracking,
    gaTrackingId,
    gaMeasurementId,
    gaContainerId,
    gaConversionId,
  } = getPublicRuntimeConfig();

  if (!gaEnableTracking) {
    return null;
  }

  return (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${gaTrackingId}', {
                    page_path: window.location.pathname,
                  });
                  gtag('config', '${gaMeasurementId}', {
                    page_path: window.location.pathname,
                    groups: 'default,ga4',
                  });
                  ${
                    gaConversionId
                      ? `gtag('config', '${gaConversionId}', {
                    page_path: window.location.pathname,
                  });`
                      : ''
                  }`,
        }}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', '${gaContainerId}');
              `,
        }}
      />
    </>
  );
}

function IeEndOfSupportScript() {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `
          if (navigator.userAgent.indexOf('MSIE') >= 0 ||
              navigator.userAgent.indexOf('Trident') >= 0) {
            window.location.href = '/ie-eos.html';
          }
        `,
      }}
    />
  );
}

function Head() {
  const { slideWebBaseUrl, isProduction } = getPublicRuntimeConfig();
  const noindex = !isProduction;

  const siteName = 'Antaa Slide';
  const _title = title('医師・医学生のためのスライド共有');
  const description =
    '医師/医学生のためのスライド共有「Antaa Slide」は、勉強会やセミナーのスライド資料を共有し、互いに閲覧できるスライド共有サービスです。日本最大級の医学スライドシェアとして1000超のスライドで勉強することが出来ます。';
  const imageUrl = `${slideWebBaseUrl}/images/ogp.png`;

  return (
    <NextHead>
      <title>{_title}</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="description" content={description} />
      <link rel="icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />

      <meta property="og:locale" content="ja_JP" key="og:locale" />
      <meta property="og:title" content={_title} key="og:title" />
      <meta property="og:site_name" content={siteName} key="og:site_name" />
      <meta property="og:type" content="website" key="og:type" />
      <meta
        property="og:description"
        content={description}
        key="og:description"
      />
      <meta property="og:image" content={imageUrl} key="og:image" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={TWITTER_ACCOUNT_CORPORATE} />

      <GaScript />
      <IeEndOfSupportScript />

      {noindex && <meta name="robots" content="noindex" />}
    </NextHead>
  );
}

function App({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  // @see https://nextjs.org/docs/basic-features/layouts#per-page-layouts
  const getLayout = Component.getLayout ?? ((page) => <Layout>{page}</Layout>);

  useAuth({ pageProps });
  useCsrf({ pageProps });
  useTermsOfServiceAgreement({ pageProps });
  usePrivacyPolicyAgreement({ pageProps });
  initFirebase(getPublicRuntimeConfig().firebaseCredential);

  // GAにPV送信
  usePageView();
  useLogRocket(getPublicRuntimeConfig().logRocketId);

  return (
    <>
      <Head />
      <ChakraProvider theme={theme}>
        {getLayout(<Component {...pageProps} />)}
      </ChakraProvider>
    </>
  );
}

function AppWithProviders(props: AppPropsWithLayout) {
  return (
    <AppContextProvider>
      <App {...props} />
    </AppContextProvider>
  );
}

export default AppWithProviders;
