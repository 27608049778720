import React, { useCallback, useEffect, useState } from 'react';
import useApiClient from '../hooks/useApiClient';
import { useAppContext } from '../contexts/app';
import monitoring from '../common/libs/monitoring';
import ProfileHospitalUpdateModal, {
  HospitalUpdateModalProps,
} from './ProfileHospitalUpdateModal';
import ProfileDepartmentUpdateModal, {
  DepartmentUpdateModalProps,
} from './ProfileDepartmentUpdateModal';

export default function ProfileUpdateContainer() {
  const { isAuthenticated } = useAppContext();
  const { apiClient } = useApiClient();
  const [shouldShowHospitalUpdateModal, setShouldShowHospitalUpdateModal] =
    useState<boolean>(false);
  const [shouldShowDepartmentUpdateModal, setShouldShowDepartmentUpdateModal] =
    useState<boolean>(false);
  const [hospitalUpdateModalConfig, setHospitalUpdateModalConfig] =
    useState<HospitalUpdateModalProps>({
      isOpen: false,
      onClose: () => {},
    });
  const [departmentUpdateModalConfig, setDepartmentUpdateModalConfig] =
    useState<DepartmentUpdateModalProps>({
      isOpen: false,
      onClose: () => {},
    });

  const callUpdateInductionNecessityApi = useCallback(async () => {
    const res = await apiClient(`/api/profile/update-induction/necessity`);
    if (res.ok) {
      const data = await res.json();
      setShouldShowHospitalUpdateModal(data.partialUpdateNecessary.hospital);
      setShouldShowDepartmentUpdateModal(
        data.partialUpdateNecessary.department,
      );
    } else {
      throw new Error();
    }
  }, [apiClient]);

  useEffect(() => {
    if (isAuthenticated) {
      callUpdateInductionNecessityApi().catch((e) =>
        monitoring.notify({
          name: `API ERROR DETECTED ${e}`,
          message: 'プロフィール更新要否の取得に失敗しました。',
        }),
      );
    }
  }, [isAuthenticated, callUpdateInductionNecessityApi]);

  useEffect(() => {
    new Promise(async () => {
      if (shouldShowHospitalUpdateModal) {
        await new Promise<boolean>((resolve) => {
          setHospitalUpdateModalConfig({
            isOpen: true,
            onClose: resolve,
          });
        });
        setHospitalUpdateModalConfig({
          isOpen: false,
          onClose: () => {},
        });
        await setShouldShowHospitalUpdateModal(false);
      }
      if (shouldShowDepartmentUpdateModal) {
        await new Promise<boolean>((resolve) => {
          setDepartmentUpdateModalConfig({
            isOpen: true,
            onClose: resolve,
          });
        });
        setDepartmentUpdateModalConfig({
          isOpen: false,
          onClose: () => {},
        });
        await setShouldShowDepartmentUpdateModal(false);
      }
    });
  }, [shouldShowDepartmentUpdateModal, shouldShowHospitalUpdateModal]);

  return (
    <>
      <ProfileHospitalUpdateModal {...hospitalUpdateModalConfig} />
      <ProfileDepartmentUpdateModal {...departmentUpdateModalConfig} />
    </>
  );
}
