import { extendTheme, StyleProps } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const FONT_FAMILY_DEFAULT =
  '"ヒラギノ角ゴ Pro W3", Hiragino Kaku Gothic Pro, "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", MS PGothic, sans-serif';

const theme = extendTheme({
  config: {
    cssVarPrefix: 'chakra',
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  colors: {
    // prettier-ignore
    brand: {
       50: '#ccffd2', // hsv(128,  20, 100)
      100: '#91f29e', // hsv(128,  40,  95)
      200: '#56d868', // hsv(128,  60,  85), dark
      300: '#26bf3a', // hsv(128,  80,  75), dark:hover
      400: '#10a524', // hsv(128,  90,  65), dark:active
      500: '#008e14', // hsv(128, 100,  55), light, company brand color
      600: '#00720f', // hsv(128, 100,  45), light:hover
      700: '#00590b', // hsv(128, 100,  35), light:active
      800: '#004c0a', // hsv(128, 100,  30)
      900: '#003f08', // hsv(128, 100,  25)
      company: '#008e14',
    },
  },
  fonts: {
    body: FONT_FAMILY_DEFAULT,
    heading: FONT_FAMILY_DEFAULT,
  },
  components: {
    Container: {
      baseStyle: {
        maxW: ['container.md', null, 'container.lg'],
      },
    },
    Link: {
      baseStyle: (props: StyleProps) => ({
        color: mode('brand.500', 'brand.200')(props),
      }),
    },
  },
  semanticTokens: {
    colors: {
      navbar: {
        default: 'white',
      },
      navbarBottom: {
        default: 'white',
      },
      searchBar: {
        default: '#F8F8F8',
      },
      searchBarPlaceholder: {
        default: 'gray.400',
      },
      tagInput: {
        default: 'white',
      },
      tagInputListOdd: {
        default: 'gray.50',
      },
      tagInputListHover: {
        default: 'gray.100',
      },
      linkText: {
        default: 'black',
      },
      footer: {
        default: '#F8F8F8',
      },
      footerText: {
        default: '#434343',
      },
      awardGold: {
        default: '#968650',
      },
    },
    shadows: {
      navbar: {
        default: 'lg',
      },
    },
  },
});

export default theme;
