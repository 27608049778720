import { useRouter } from 'next/router';

export default function useAppRouter() {
  const router = useRouter();
  return {
    ...router,
    pushHome() {
      router.push('/');
    },
    pushLogin(options?: { redirectUrl?: string }) {
      const p = new URLSearchParams();
      if (options?.redirectUrl) {
        p.set('redirectUrl', options.redirectUrl);
      }
      router.push(`/login?${p.toString()}`);
    },
  };
}
